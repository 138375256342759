import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import tw, { styled } from "twin.macro"
import "styled-components/macro"
import _ from "lodash"

const HeroContainer = styled.div`
  ${tw`w-full relative 
  after:content-['']
  after:absolute
  after:top-0
  after:left-0
  after:w-full
  after:h-full
  after:bg-gradient-to-b 
  after:from-transparent 
  after:via-transparent
  // after:to-black/75
  `}
  height: ${({ imgHeight }) => (imgHeight ? `${imgHeight}px` : "auto")};
`

const HeroTitle = styled.h3`
  ${tw`absolute bottom-0 text-white pb-8 text-center w-full text-3xl 
  after:content-['']
  after:relative
  after:h-0.5 
  after:w-8 
  after:mt-2.5 
  after:bg-[#EC1F27]
  after:mx-auto
  after:block
  after:transition-all
  z-10
  hover:after:w-16
  `}
`

const Hero = ({ children, img, text, imgHeight, imgPosition }) => {
  const image = getImage(img)
  return (
    <HeroContainer imgHeight={imgHeight}>
      <GatsbyImage
        image={image}
        alt={text || "Hero Image"}
        objectFit="cover"
        objectPosition={imgPosition || "50% 50%"}
        tw="w-full h-full"
      />
      {!_.isEmpty(children)
        ? children
        : !_.isEmpty(text) && <HeroTitle>{text}</HeroTitle>}
    </HeroContainer>
  )
}

export default Hero
